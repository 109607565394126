// access-control.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccessControlGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot) {

    const requiredPage = route.data['requiredPage'];

    if (!requiredPage) {
      console.error('Route missing requiredPage data');
      return false;
    }

    if (await this.auth.hasPageAccess(requiredPage)) {
      return true;
    }

    return false;
  }
}