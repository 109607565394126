import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class MyHttpInterceptorInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor(private loadingService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    this.loadingService.setLoading(true);
    const token = localStorage.getItem('token');
    const ignore =
      typeof request.body === 'undefined'
      || request.body === null
      || request.body.toString() === '[object FormData]'
      || request.headers.has('Content-Type');
    if (ignore) {
      let clonedOptions: any = {
        setHeaders: {
          'Content-Language': 'all',
          Authorization: `Bearer ${token}`,
          Accepts: 'application/json',
        },
        responseType: 'json',
      };

      const isS3Upload = request.url.includes('s3.amazon');
      if (isS3Upload) {
        clonedOptions = {
          setHeaders: {
            'Content-Language': 'all',
            Accepts: 'application/xml',
          },
          responseType: 'text'
        }
      }

      if (request.responseType === 'blob') {
        clonedOptions = {
          setHeaders: {
            'Content-Language': 'all',
            Authorization: `Bearer ${token}`,
            Accepts: '*/*',
          },
          responseType: 'blob'
        }
      }

      request = request.clone(clonedOptions);
      return next.handle(request).pipe(
        finalize(() => {
          this.totalRequests--;
          if (this.totalRequests == 0) {
            this.loadingService.setLoading(false);
          }
        })
      );
    }

    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Content-Language': 'all',
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false);
        }
      })
    );
  }
}

