import { AnonymousGuard } from './services/anonymous-guard.service';
import { AuthGuard } from './services/auth.guard.service';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AccessControlGuard } from './services/access-control-guard.service';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/users/list' },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canActivate: [AnonymousGuard], data: { requiredPage: 'auth' } },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'dashboard' } },
  { path: 'requests', loadChildren: () => import('./pages/requests/requests.module').then(m => m.RequestsModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'requests' } },
  { path: 'newsletter', loadChildren: () => import('./pages/newsletter/newsletter.module').then(m => m.NewsletterModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'newsletter' } },
  { path: 'photos', loadChildren: () => import('./pages/photos/photos.module').then(m => m.PhotosModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'photos' } },
  { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'users' } },
  { path: 'events', loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'events' } },
  { path: 'categories', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'categories' } },
  { path: 'cities', loadChildren: () => import('./pages/cities/cities.module').then(m => m.CitiesModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'cities' } },
  { path: 'provinces', loadChildren: () => import('./pages/provinces/provinces.module').then(m => m.ProvincesModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'provinces' } },
  { path: 'ticket-providers', loadChildren: () => import('./pages/ticket-providers/ticket-providers.module').then(m => m.TicketProvidersModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'ticket-providers' } },
  { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'orders' } },
  { path: 'tickets', loadChildren: () => import('./pages/tickets/tickets.module').then(m => m.TicketsModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'tickets' } },
  { path: 'events-sections', loadChildren: () => import('./pages/events-sections/events-sections.module').then(m => m.EventsSectionsModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'events-sections' } },
  { path: 'banners', loadChildren: () => import('./pages/banners/banners.module').then(m => m.BannersModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'banners' } },
  { path: 'locations', loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'locations' } },
  { path: 'informative-icons', loadChildren: () => import('./pages/informative-icon/informative-icon.module').then(m => m.InformativeIconModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'informative-icons' } },
  { path: 'entertainment', loadChildren: () => import('./pages/entertainment/entertainment.module').then(m => m.EntertainmentModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'entertainment' } },
  { path: 'entertainment-alhokair', loadChildren: () => import('./pages/alhokair-entertainment/alhokair-entertainment.module').then(m => m.AlhokairEntertainmentModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'entertainment-alhokair' } },
  { path: 'notification', loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'notification' } },
  { path: 'almosafer', loadChildren: () => import('./pages/almosafer/almosafer.module').then(m => m.AlmosaferModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'almosafer' } },
  { path: 'web-pages', loadChildren: () => import('./pages/web-pages/web-pages.module').then(m => m.WebPagesModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'web-pages' } },
  { path: 'access-control', loadChildren: () => import('./pages/access-control/access-control.module').then(m => m.AccessControlModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'access-control' } },
  { path: 'admins', loadChildren: () => import('./pages/admins/admins.module').then(m => m.AdminsModule), canActivate: [AuthGuard, AccessControlGuard], data: { requiredPage: 'admins' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  getRoutes() {
    return routes;
  }
}
