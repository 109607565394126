import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private httpClient: HttpClient,
  ) { }

  register(userData: {}) {
    return this.httpClient.post(`${environment.apiURL}api/team/user/register`, userData)
  }

  login(userData: {}) {
    return this.httpClient.post(`${environment.apiURL}api/team/user/login`, userData)
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token')
    if (!token) {
      return false;
    }
    const { roles } = this.jwtHelper.decodeToken(token);
    if (!roles || (roles.length && roles[0] !== 'team')) {
      return false;
    }
    if (this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    return true;
  }


  public getToken(): string {
    const token = localStorage.getItem('token') || '';

    // return token ? JSON.parse(token) : null;
    return token
  }

}
