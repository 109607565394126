import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  listEndsUser({ limit, page }: { limit?: number, page?: number }) {
    return this.httpClient.get(`${environment.apiURL}api/team/user/list/?role=customer&limit=${limit}&page=${page}`)
  }

  viewCustomer({ customerId }: { customerId: string }) {
    return this.httpClient.get(`${environment.apiURL}api/team/user/view-customer-info/${customerId}`)
  }

  viewUser() {
    return this.httpClient.get(`${environment.apiURL}api/team/user/view/`)
  }
}
