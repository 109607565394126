<nz-layout *ngIf="isAuthenticated" dir="rtl" class="app-layout">
  <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <img src="/assets/tktahlogo.svg" alt="logo" />
    </div>
    <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item [routerLink]="['/users']" *ngIf="authService.hasPageAccess('users')">
        <span nz-icon nzType="user"></span>
        <span>المستخدمين</span>
      </li>
      <li nz-menu-item [routerLink]="['/ticket-providers']" *ngIf="authService.hasPageAccess('ticket-providers')">
        <span nz-icon nzType="usergroup-add" nzTheme="outline"></span>
        <span>مزودي التذاكر</span>
      </li>

      <li nz-menu-item [routerLink]="['/categories']" *ngIf="authService.hasPageAccess('categories')">
        <span nz-icon nzType="database" nzTheme="outline"></span>
        <span>تصنيف الفعاليات</span>
      </li>
      <li nz-menu-item [routerLink]="['/events']" *ngIf="authService.hasPageAccess('events')">
        <span nz-icon nzType="wallet" nzTheme="outline"></span>
        <span>الفعاليات</span>
      </li>

      <li nz-submenu nzTitle="مراكز الترفيه" nzIcon="ordered-list" *ngIf="
          authService.hasPageAccess('entertainment') ||
          authService.hasPageAccess('entertainment-alhokair')
        ">
        <ul>
          <li nz-menu-item [routerLink]="['/entertainment']" *ngIf="authService.hasPageAccess('entertainment')">
            <span nz-icon nzType="play-circle" nzTheme="outline"></span>
            <span>مراكز الترفيه</span>
          </li>
          <li nz-menu-item [routerLink]="['/entertainment-alhokair']"
            *ngIf="authService.hasPageAccess('entertainment-alhokair')">
            <span nz-icon nzType="play-circle" nzTheme="outline"></span>
            <span>مركز ترفيه الحكير</span>
          </li>
        </ul>
      </li>

      <li nz-submenu nzTitle="المسافر" nzIcon="ordered-list" *ngIf="authService.hasPageAccess('almosafer')">
        <ul>
          <li nz-menu-item [routerLink]="['/almosafer/activities']">
            <span>الأنشطة</span>
          </li>
          <li nz-menu-item [routerLink]="['/almosafer/orders']">
            <span>الطلبات</span>
          </li>
          <li nz-menu-item [routerLink]="['/almosafer/categories']">
            <span>الاصناف</span>
          </li>
          <li nz-menu-item [routerLink]="['/almosafer/cities']">
            <span>المدن</span>
          </li>
          <li nz-menu-item [routerLink]="['/almosafer/icons']">
            <span>الايقونات</span>
          </li>
        </ul>
      </li>
      <li nz-menu-item [routerLink]="['/notification']" *ngIf="authService.hasPageAccess('notification')">
        <span nz-icon nzType="notification" nzTheme="outline"></span>
        <span>الإشعارات</span>
      </li>
      <li nz-menu-item [routerLink]="['/cities']" *ngIf="authService.hasPageAccess('cities')">
        <span nz-icon nzType="build" nzTheme="outline"></span>
        <span>المدن</span>
      </li>
      <li nz-menu-item [routerLink]="['/provinces']" *ngIf="authService.hasPageAccess('provinces')">
        <span nz-icon nzType="build" nzTheme="outline"></span>
        <span>المناطق</span>
      </li>
      <li nz-menu-item [routerLink]="['/locations']" *ngIf="authService.hasPageAccess('locations')">
        <span nz-icon nzType="global" nzTheme="outline"></span>
        <span>المواقع</span>
      </li>
      <li nz-menu-item [routerLink]="['/informative-icons']" *ngIf="authService.hasPageAccess('informative-icons')">
        <span nz-icon nzType="file-image" nzTheme="outline"></span>
        <span>ايقونات اعلامية</span>
      </li>
      <li nz-submenu nzTitle="الطلبات" nzIcon="ordered-list" *ngIf="authService.hasPageAccess('requests')">
        <ul>
          <li nz-menu-item [routerLink]="['/requests/ticket-providers']">
            موزعي التذاكر
          </li>
          <li nz-menu-item [routerLink]="['/requests/companies-reservations']">
            حجز الشركات
          </li>
          <li nz-menu-item [routerLink]="['/requests/job-requests']">
            طلبات العمل
          </li>
        </ul>
      </li>
      <li nz-menu-item [routerLink]="['/orders']" *ngIf="authService.hasPageAccess('orders')">
        <span nz-icon nzType="ordered-list" nzTheme="outline"></span>
        <span>المشتريات</span>
      </li>
      <li nz-menu-item [routerLink]="['/tickets']" *ngIf="authService.hasPageAccess('tickets')">
        <span nz-icon nzType="snippets" nzTheme="outline"></span>
        <span>التذاكر</span>
      </li>
      <li nz-menu-item [routerLink]="['/events-sections']" *ngIf="authService.hasPageAccess('events-sections')">
        <span nz-icon nzType="partition" nzTheme="outline"></span>
        <span>اقسام الفعاليات</span>
      </li>
      <li nz-menu-item [routerLink]="['/banners']" *ngIf="authService.hasPageAccess('banners')">
        <span nz-icon nzType="picture" nzTheme="outline"></span>
        <span>البانرز</span>
      </li>
      <li nz-menu-item [routerLink]="['/newsletter']" *ngIf="authService.hasPageAccess('newsletter')">
        <span nz-icon nzType="read" nzTheme="outline"></span>
        <span>النشرة البريدية</span>
      </li>
      <li nz-submenu nzTitle="الاعدادت" nzIcon="setting" *ngIf="
          authService.hasPageAccess('access-control') ||
          authService.hasPageAccess('admins') ||
          authService.hasPageAccess('web-pages')
        ">
        <ul>
          <li nz-menu-item [routerLink]="['/web-pages']" *ngIf="authService.hasPageAccess('web-pages')">
            الصفحات
          </li>
          <li nz-menu-item [routerLink]="['/access-control']" *ngIf="authService.hasPageAccess('access-control')">
            التحكم في الوصول
          </li>
          <li nz-menu-item [routerLink]="['/admins']" *ngIf="authService.hasPageAccess('admins')">
            الفريق
          </li>
        </ul>
      </li>
      <li nz-menu-item (click)="logout()">
        <span nz-icon nzType="logout" nzTheme="outline"></span>
        <span>تسجيل الخروج</span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

<nz-layout *ngIf="!isAuthenticated">
  <nz-content>
    <div>
      <router-outlet></router-outlet>
    </div>
  </nz-content>
</nz-layout>
<app-spinner></app-spinner>