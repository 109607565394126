import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsersService } from './users.service';

interface PageAccess {
  webPageId: { name: string };
  access: 'readOnly' | 'fullAccess';
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  jwtHelper: JwtHelperService = new JwtHelperService();
  private accessControls: PageAccess[] = [];
  roles: any;
  userId: string = '';
  customer: any;
  userDetailsAlreadyCalled: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private usersService: UsersService
  ) { }

  register(userData: {}) {
    return this.httpClient.post(`${environment.apiURL}api/team/user/register`, userData)
  }

  login(userData: {}) {
    return this.httpClient.post(`${environment.apiURL}api/team/user/login`, userData)
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token')
    if (!token) {
      return false;
    }
    const { roles } = this.jwtHelper.decodeToken(token);
    if (!roles || (roles.length && !roles.includes('team'))) {
      return false;
    }
    if (this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    return true;
  }


  public getToken(): string {
    const token = localStorage.getItem('token') || '';
    return token
  }

  getUserDetails() {
    if (!this.userDetailsAlreadyCalled) {
      this.userDetailsAlreadyCalled = true;
      const rolesString = localStorage.getItem('roles');
      this.roles = rolesString ? rolesString.split(',') : [];
      const accessControls = localStorage.getItem('accessControls');
      this.accessControls = accessControls ? JSON.parse(accessControls) : [];
    }
  }

  hasPageAccess(pageName: string): boolean {
    this.getUserDetails();
    if (this.roles.includes('super-admin')) return true;
    return this.accessControls.some((a) => a.webPageId.name === pageName);
  }

  hasAccessLevel(pageName: string, accessLevel: 'readOnly' | 'fullAccess'): boolean {
    this.getUserDetails();
    if (this.roles.includes('super-admin')) return true;
    const page = this.accessControls.find((a) => a.webPageId.name === pageName);
    if (!page) return false;
    return accessLevel === 'readOnly' || page.access === 'fullAccess';
  }
}