import { Component } from '@angular/core';
import { AuthService } from "./services/auth.service";
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCollapsed = false;
  isAuthenticated: boolean;

  constructor(private authService:AuthService, private msg: NzMessageService){
    this.isAuthenticated = this.authService.isAuthenticated()
   }

   logout() {
    localStorage.removeItem('token');
    this.msg.success('تم تسجيل الخروج بنجاح');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
