import { Component } from '@angular/core';
import { AuthService } from "./services/auth.service";
import { NzMessageService } from 'ng-zorro-antd/message';
import { UsersService } from './services/users.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCollapsed = false;
  isAuthenticated!: boolean;

  constructor(
    public authService: AuthService, private msg: NzMessageService,
    private usersService: UsersService
  ) {
  }

  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated()
    if (this.isAuthenticated) {
      this.getUserDetails();
    }
  }

  logout() {
    localStorage.clear()
    this.msg.success('تم تسجيل الخروج بنجاح');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  getUserDetails() {
    this.usersService.viewUser().subscribe((res: any) => {
      localStorage.setItem('roles', res.user.roles || []);
      localStorage.setItem('accessControls', JSON.stringify(res.user.accessControlId?.pages || []));
    })
  }
}